
import Component from "vue-class-component";
import Vue from "vue";
import { AssetClass, StrategyRun, StrategyRunListFilters, StrategyRunSummary } from "next-trader-api";
import { assetClassApi, botApi } from "@/plugins/api";
import { map, switchMap } from "rxjs/operators";
import {
  mdiAutoFix,
  mdiChartBox,
  mdiCheck,
  mdiChevronDown,
  mdiChevronUp,
  mdiDotsVertical,
  mdiGamepadVariant,
  mdiMinus,
  mdiTrashCanOutline,
} from "@mdi/js";
import { Prop } from "vue-property-decorator";
import ProfitPercent from "@/components/ProfitPercent.vue";
import StrategyRunGroupSummaryChart from "@/components/StrategyRunGroup/StrategyRunGroupSummaryChart.vue";
import StrategyRunCreate from "@/components/StrategyRunCreate.vue";
import StrategyRunGroupFilters from "@/components/StrategyRunGroup/StrategyRunGroupFilters.vue";
import BotListCreate from "@/components/BotList/BotListCreate.vue";
import AvatarIdenticon from "@/components/General/AvatarIdenticon.vue";
@Component({
  components: {
    AvatarIdenticon,
    BotListCreate,
    StrategyRunGroupFilters,
    StrategyRunCreate,
    StrategyRunGroupSummaryChart,
    ProfitPercent,
  },
})
export default class StrategyRunList extends Vue {
  @Prop({ default: 25 })
  itemPerPage?: number;

  strategyRuns: Array<StrategyRun> = [];
  filters: StrategyRunListFilters = {};

  assetClasses: Array<AssetClass> = [];

  isNewFormOpen = false;

  icons = {
    mdiAutoFix: mdiAutoFix,
    mdiChartBox: mdiChartBox,
    mdiChevronUp: mdiChevronUp,
    mdiChevronDown: mdiChevronDown,
    mdiCheck: mdiCheck,
    mdiMinus: mdiMinus,
    mdiGamepadVariant: mdiGamepadVariant,
    mdiDotsVertical: mdiDotsVertical,
    mdiTrashCanOutline: mdiTrashCanOutline,
  };

  getStrategyRunGet$() {
    return botApi
      .postAppBotList({
        strategyRunListRequest: {},
      })
      .pipe(
        map((next: Array<StrategyRun>) => {
          this.strategyRuns = next;
          return next;
        })
      );
  }

  getAssetClassGet$() {
    return assetClassApi.getAppAssetclassGetall().pipe(
      map((next: Array<AssetClass>) => {
        this.assetClasses = next;
        return next;
      })
    );
  }

  created() {
    this.getStrategyRunGet$().subscribe();
    this.getAssetClassGet$().subscribe();
  }

  headers: Array<{ text: string; value: string }> = [
    { text: "Name", value: "name" },
    { text: "Markets", value: "strategyRunMarkets" },
    { text: "Candle size", value: "candleSize" },
    { text: "Net profit", value: "netProfit" },
    { text: "Is running", value: "isRunning" },
    { text: "Started at", value: "startedAt" },
    { text: "Actions", value: "actions" },
  ];

  onStrategyRunCreated() {
    this.getStrategyRunGet$().subscribe();
  }

  onFilter() {
    this.getStrategyRunGet$().subscribe();
  }

  getNetProfitPercent(strategyRunSummary: StrategyRunSummary) {
    return strategyRunSummary ? strategyRunSummary.netProfitPercent : undefined;
  }

  getSortBy() {
    return ["startedAt"];
  }

  getSortDesc() {
    return [true];
  }

  deleteItem(item) {
    if (confirm("Do you really want to delete this?")) {
      botApi
        .deleteAppBotRemove({
          strategyRunId: item.id,
        })
        .pipe(switchMap(() => this.getStrategyRunGet$()))
        .subscribe();
    }
  }
}
