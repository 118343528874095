
import Component from "vue-class-component";
import Vue from "vue";
import { botApi, marketApi, sourceApi, strategyApi } from "@/plugins/api";
import { BotCreateRequest, Market, Source, Strategy } from "api/models";
import { mdiCalendar, mdiCheck, mdiPlay, mdiTrendingUp, mdiWindowClose } from "@mdi/js";
import { uniqueNamesGenerator, adjectives, animals } from "unique-names-generator";
@Component
export default class BotListCreate extends Vue {
  valid = true;

  markets: Array<Market> = [];
  sources: Array<Source> = [];
  strategies: Array<Strategy> = [];
  exitStrategies: Array<Strategy> = [];

  snackbar = false;
  snackbarText = "";

  icons = {
    mdiTrendingUp: mdiTrendingUp,
    mdiCalendar: mdiCalendar,
    mdiPlay: mdiPlay,
    mdiWindowClose: mdiWindowClose,
    mdiCheck: mdiCheck,
  };

  params: BotCreateRequest = {
    name: "",
    marketIds: [],
    emulateOrderExecution: true,
    strategyId: 756, //8403,
    exitStrategyId: 8409,
    entryStrategyParams: "2", //"10,10,8,4,20,1000",
    exitStrategyParams: "0.04,0.01,150,30",
    candleSize: "5s",
    mainCurrencyId: 1,
    strategyInitialBalance: { exchangeId: 1, currencyId: 1, value: 100000 },
    allowAnyMarkets: true,
  };
  initialBalanceCurrencyName = "USDT";

  created() {
    this.params.name = uniqueNamesGenerator({
      dictionaries: [adjectives, animals],
      separator: " ",
      length: 2,
      style: "capital",
    });

    marketApi.getAppMarketGetall().subscribe((markets: Array<Market>) => {
      this.markets = markets;
    });
    sourceApi.getAppSourceGetall().subscribe((sources: Array<Source>) => {
      this.sources = sources;
    });
    strategyApi.getAppStrategyGetall().subscribe((strategies: Array<Strategy>) => {
      this.strategies = strategies.filter((item) => item.type === "entry");
      this.exitStrategies = strategies.filter((item) => item.type === "exit");
    });
  }

  createBot() {
    this.snackbarText = "Bot is being created...";
    this.snackbar = true;

    botApi.postAppBotCreate({ botCreateRequest: this.params }).subscribe(() => {
      this.$emit("created");
      this.snackbarText = "Bot is created.";
      this.snackbar = true;
    });
  }
}
